<template>
  <div>




  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th v-for="(col, colIndex) in columns" :key="colIndex">
          {{ col }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="record in records" :key="record.id">
        <td v-for="(col, colIndex) in columns" :key="record.id + '-' + colIndex" align="left">
          {{ record.fields[col] }}
        </td>
      </tr>
    </tbody>
  </table>
      <vue-json-pretty :path="'res'" :data="this.records" @click="handleClick"> </vue-json-pretty>
</div>
</template>

<script>
import axios from 'axios';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
//alert(2)
export default {
  name: 'VueAirtable',
  components: {
  VueJsonPretty,
  },
  props: [
    'base',
    'columns',
    'filter',
    'sort'
  ],
  data: function () {
    return {
      apiUrl: 'https://api.airtable.com/v0/',
      apiKey: 'keyi3vRCerzet6FAp',//process.env.AIRTABLE_API_KEY, // Always use a read-only account token
      records: []
    };
  },
  mounted: function () {
    if (!this.base || !this.columns) {
      return console.error('Please specify `base` and `columns` attributes for <vue-airtable> component.');
    }
    this.getData();
  },
  watch: {
    table: function (newTable, oldTable) {
      this.getData();
    }
  },
  methods: {
    getData: function () {
      axios({
        url: this.apiUrl + this.base,
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        },
        params: {
          filterByFormula: this.filter || '',
          sort: this.sort || ''
        }
      }).then((res) => {
        this.records = res.data.records;
      });
    }
  }
}
</script>
