<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <notification class="is-info">
        <div>
          <span><b>Demo only.</b> No data will be saved/updated</span>
        </div>
      </notification>
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="cogs"
          class="tile is-child"
        >
          <form @submit.prevent="submit">


            <!--     <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr />

       <b-field label="Avatar" horizontal>
              <file-picker />
            </b-field>
            <hr />
          -->




            <b-field label="Base ID" message="Retrieve it on Airtable" horizontal>
              <b-input
                v-model="baseId"
                placeholder="e.g. app12345"
                required
              />
            </b-field>



            <b-field label="Table" message="Name of the primary tab in your base" horizontal>
              <b-input
                v-model="tableName"
                placeholder="e.g. Table1"
                required
              />
            </b-field>






            <b-field horizontal>
              <b-button
                type="is-info"
                icon-right="chevron-right"
                :loading="isLoadingAt"
                @click="updateBaseId"
                >Test connection </b-button
              >
            </b-field>

    <hr />






    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th v-for="(col, colIndex) in columns" :key="colIndex">
            {{ col }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="record.id">
          <td v-for="(col, colIndex) in columns" :key="record.id + '-' + colIndex" align="left">
            {{ record.fields[col] }}
          </td>
        </tr>
      </tbody>
    </table>



<!--

filter="AND({State} = 'CA', {Status} = 'Open', {Type} = 'Fulfillment')"
:sort="[{field: 'Warehouse Code', direction: 'asc'}]"

<vueAirtable base="app3g2YEaIWF3ztdN/Design"
:columns="['Name', 'Client', 'Category', 'Sales Tax Rate']"
></vueAirtable>
-->


<h2 class="title is-3">Options  </h2>

<div class="block">

 <b-switch v-model="customDATA">
    Use camelCase on column names
</b-switch><br/>

    <b-switch v-model="restrictCols">
    Customize included columns
  </b-switch><br/>

<!--
            <b-radio v-model="radio"
                name="name"
                native-value="Flint">
                All
            </b-radio>
            <b-radio v-model="radio"
                name="name"
                native-value="Silver">
                 Select properties manually
            </b-radio>
-->
        </div>




<div class="manualCols box chkboxlist" v-show="restrictCols">

  <p>Personalize your feed JSON feed content</p>

  <b-tabs v-model="activeTab"  type="is-boxedNO" :multiline="multiline">
    <b-tab-item
        icon="table"
        key="primary"
        value="primary"
        :label="tableName">




<!-- PRIMARy TABB-->
<div   v-for="i in dataKeys" style="width:100%;">
<b-checkbox v-model="selectedCols"
   type="is-info"
                :native-value="i.name">
                {{i.name}}

                <b-tooltip :label="i.type || i.typeof " position="is-right">
                  <b-icon
                  :icon="i.icon"
                  size="is-small"/>
             </b-tooltip>
</b-checkbox>

 <b-dropdown v-if="i.isLink && isSelectedCols(i.name)"   aria-role="list" size="is-small">

  <template   #trigger>


     <b-button
      v-if="  linkedTabs && linkedTabs[i.name]"
         outlined
         size="is-small"
         :label="linkedTabs[i.name]"
         type="is-success"
         icon-left="arrow-left"
         rounded
         icon-right="menu-down" />
      <b-button
      v-else
          outlined
          rounded
          size="is-small"
          label="No embded data"
          type="is-danger"
          icon-right="menu-down" />
  </template>




  <b-dropdown-item   aria-role="listitem" @click="setLinkedTab(i.name, null)">
      <div class="media" >
          <b-icon class="media-left" icon="close"></b-icon>
          <div class="media-content">
              <h3>No embded data</h3>
              <small>Only reference the linked Airtable ID. Lightweight.</small>
          </div>
      </div>
  </b-dropdown-item>



<b-dropdown-item   aria-role="listitem"
v-for="(lt, k) in linkedTabsUnique"
@click="setLinkedTab(i.name, lt)"
>
  <div class="media">
      <b-icon class="media-left" icon="table"></b-icon>
      <div class="media-content">
          <h3>{{lt}}  </h3>
          <small>Embed records from the <b>{{lt}}</b> table</small>
      </div>
  </div>
</b-dropdown-item>

  <b-dropdown-item  @click="createLinkedTab(i.name)" aria-role="listitem" >
      <div class="media">
          <b-icon class="media-left" icon="plus"></b-icon>
          <div class="media-content">
              <h3>Link a table</h3>
              <small>Specefy the name of the linked table.</small>
          </div>
      </div>
  </b-dropdown-item>
</b-dropdown>



</div>






    </b-tab-item>


                <b-tab-item
                    v-for="i in linkedTabsUniqueInUse "
                    icon="link"
                    :key="i"
                    :value="i"
                    :label="i">



<br>
<b-switch v-model="embededPropppp1">
   Embed all <b>{{i}}</b> data
</b-switch><br/><br/>

<div  v-show="!embededPropppp1"  v-for="i in dataKeys" style="width:100%;">
<b-checkbox v-model="selectedCols"
   type="is-info"
                :native-value="i.name">
                {{i.name}}

                <b-tooltip :label="i.type || i.typeof " position="is-right">
                  <b-icon
                  :icon="i.icon"
                  size="is-small"/>
             </b-tooltip>
</b-checkbox>
</div>




                </b-tab-item>

        </b-tabs>






          <!--  <b-checkbox v-model="checkboxGroup"
                native-value="Flint">
                Flint
            </b-checkbox>
            <b-checkbox v-model="checkboxGroup"
                          native-value="Vane">
                          Vane
                      </b-checkbox>
                      <b-checkbox v-model="checkboxGroup"
                          native-value="Billy" disabled>
                          Billy
                      </b-checkbox> -->

                      <p class="content">
                               <b>Selection:</b>
                               {{ selectedCols }}
                           </p>


                                                    linkedTabs:
                             <vue-json-pretty :path="'res'" :data="this.linkedTabs" @click="handleClick"> </vue-json-pretty>

                             <hr />
</div>




<h2 class="title is-5">Embed linked records</h2>
<p class="content">
         Include related records data into your primary feed.
         <br>This can quickly increase the filesize of your json.
     </p>
  <hr />


  <div class="manualCols chkboxlist" v-show="restrictCols">
    <p>Select columns to include in your datafeed</p>
    <b-checkbox v-model="linkedChk"
        v-for="i in linkedKeysToDisplay"
                    :native-value="i.name">
                    {{i.name}}



                    <!--
                    <b-icon
                    v-if="i.isUser"
                icon="account"
                size="is-small"/>
                <b-icon
                v-if="i.isLink"
                icon="link"
                size="is-small"/>
                <b-icon
                v-if="i.isImage"
                icon="image"
                size="is-small"/>

                <b-icon
                v-if="i.airtableId"
                icon="key"
                size="is-small"/>


                    <code>{{i.type || i.typeof}}</code>
                  -->
                </b-checkbox>


            <!--  <b-checkbox v-model="checkboxGroup"
                  native-value="Flint">
                  Flint
              </b-checkbox>
              <b-checkbox v-model="checkboxGroup"
                            native-value="Vane">
                            Vane
                        </b-checkbox>
                        <b-checkbox v-model="checkboxGroup"
                            native-value="Billy" disabled>
                            Billy
                        </b-checkbox> -->

                        <p class="content">
                                 <b>Selection:</b>
                                 {{ linkedChk }}
                             </p>

                               <hr />
  </div>



    <b-checkbox v-model="customElementsForm">
      Airlines
    </b-checkbox><br/>
    <b-checkbox v-model="customElementsForm">
      Passengers
    </b-checkbox>
    <br/>

    <div class="box">
      <b-checkbox v-model="customElementsForm">
        <b>People</b>
      </b-checkbox>

      <br><br>

      <b-field label="Linked tab" message=" Type the linked tab name for this column" label-position="on-border" >
        <b-input

          v-model="form.name"
          size="is-small"
          style="max-width:300px;"
          placeholder="e.g. Users"

        />
      </b-field>


      <b-switch v-model="customElementsForm" size="is-small">
        Customize embeded columns
      </b-switch>
      <h2 class="title is-6">Embeded values for these columns:  </h2>


                  <b-checkbox v-model="checkboxGroup" size="is-small"
                      native-value="Flint">
                      Flint
                  </b-checkbox>
                  <b-checkbox v-model="checkboxGroup"
                                native-value="Vane">
                                Vane
                            </b-checkbox>
                            <b-checkbox v-model="checkboxGroup"
                                native-value="Billy" disabled>
                                Billy
                            </b-checkbox>

                            <p class="content">
                                     <b>Selection:</b>
                                     {{ checkboxGroup }}
                                 </p>

        <hr />


    </div>
  <hr />




  <b-field label="Save feed as..." message=" " horizontal>
    <b-input
      v-model="form.name"
      placeholder="e.g. List of all airports (internal reference only)"

    />
  </b-field>


  linkedTabs
  <vue-json-pretty :path="'res'" :data="this.linkedTabs"  </vue-json-pretty>


  keyTypes
  <vue-json-pretty :path="'res'" :data="this.dataKeys" @click="handleClick"> </vue-json-pretty>

<vue-json-pretty :path="'res'" :data="this.airtableData" @click="handleClick"> </vue-json-pretty>

<vue-json-pretty :path="'res'" :data="this.records" @click="handleClick"> </vue-json-pretty>

xxxxxxx



        <!--
    <b-field label="Slugify columns labels" horizontal>
      <b-switch v-model="customElementsForm">
        Replace all accents, spaces and special chars to simplify data-handling on the client-side.
      </b-switch><br/>

    </b-field>
    <hr />



            <b-field label="City" message="Feed's city" horizontal>
              <b-input
                v-model="form.city"
                placeholder="e.g. Geoffreyton"
                required
              />
            </b-field>

            <hr />
            <b-field label="Progress" horizontal>
              <b-slider v-model="form.progress" />
            </b-field>
-->

            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Save</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="JSON Preview"
          icon="link"
          class="tile is-child"
        >

        <vue-json-pretty :path="'res'" :data="{ key: ['value', 123] }" @click="handleClick"> </vue-json-pretty>



        <!--  <user-avatar
            :avatar="form.avatar"
            class="image has-max-width is-aligned-center"
          />
          <hr />-->
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Table name">
            <b-input :value="form.company" custom-class="is-static" readonly />
          </b-field>
          <b-field label="City">
            <b-input :value="form.city" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created">
            <b-input
              :value="createdReadable"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <hr />
          <b-field label="Progress">
            <progress
              class="progress is-small is-primary"
              :value="form.progress"
              max="100"
              >{{ form.progress }}</progress
            >
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import find from 'lodash/find'
import {
  camelCasify,
  bumpyCasify,
  kebabCasify,
  snakeCasify,
  startCasify,
  upperCasify,
  lowerCasify
} from 'casify';


import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import FilePicker from '@/components/FilePicker'
// import UserAvatar from '@/components/UserAvatar'
import Notification from '@/components/Notification'

import VueAirtable from '@/components/VueAirtable'

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';


export default {
  name: 'FeedForm',
  components: {
//    UserAvatar,
  VueJsonPretty,
  VueAirtable,
    FilePicker,
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    Notification
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      checkboxGroup: [],
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false,
      apiUrl: 'https://api.airtable.com/v0/',
      apiKey: 'keyi3vRCerzet6FAp',//process.env.AIRTABLE_API_KEY, // Always use a read-only account token
      records: [],
      baseId: 'app3g2YEaIWF3ztdN',
      tableName: 'Design',
      airtableIdProp: '_id_airtable',
      isLoadingAt: false,
      restrictCols: true,
      selectedCols: [],
      activeTab: 'primary',
      embededPropppp1: true, //temporary
      linkedTablesData: {},
      linkedTabs: {
        'Client': 'Organizations',
          'zcefdsf': 'Organizations',
            'Pizadssaaa': 'People',
              'Pizaaaa': 'Rhino',
      },
    }
  },
  computed: {
    dataKeys () {
      var at = this.airtableData;
      if(!at || !at[0]) return [];
      var   example = at[0];
      var keyTypes = [];
      let keys = Object.keys(example);
      console.log(keys, 'kkkkeryyy')
      keys.forEach(key => {
        keyTypes.push({name: key, value:example[key], typeof: typeof(example[key])})
      })
      keyTypes = _.map(keyTypes, (k) => {



        if(k.typeof == 'string' && k.value.startsWith('rec')){
          k.type='singleLink'
          k.isLink=true;
        }
        if(k.name == this.airtableIdProp  ){
          k.type='airtableId'
          k.airtableId=true;
          k.isLink=false;
        }


        if(k.typeof == 'object' && k.value.length){
          var r = k.value[0]
          if(r && r.startsWith && r.startsWith('rec')){
            k.type='multiLink'
            k.isLink=true;
            k.isArray=true;
          }
          if(r && r.filename && r.thumbnails && r.url){
            k.type='imageEmbed'
            k.isImage=true;
          //  k.isArray=true;
          }
          if(r && r.id && r.email && r.name){
            k.type='user'
            k.isUser=true;
          //  k.isArray=true;
          }
          k
        }

        k.icon = 'info';//default
        if(k.isUser)k.icon = 'account';
        if(k.isLink)k.icon = 'link';
        if(k.isImage)k.icon = 'image';
        if(k.airtableId)k.icon = 'database';

        return k
      })
      return keyTypes
    },
    nonLinkedKeys () {
      var kkk = _.filter(this.dataKeys, (k) => {
         return !k.isLink
      });
      return kkk
    },
    linkedKeys () {
      var kkk = _.filter(this.dataKeys, (k) => {
         return k.isLink
      });
      return kkk
    },
    linkedKeysToDisplay () {
      var kkk = _.filter(this.dataKeys, (k) => {
         if(!k.isLink ) return false;
         if(!this.restrictCols ) return true; //show all...
         return _.includes(this.selectedCols, k.name)
      });
      return kkk
    },
    linkedTabsUnique () {
       var ll = this.linkedTabs;
       ll = Object.values( ll );
       return _.compact(_.uniq(ll))
    },
    linkedTabsUniqueInUse() {
       var ll = this.linkedKeysToDisplay;
      ll = _.map(ll, (l) =>{
        return this.linkedTabs[l.name]
      })
       return _.compact(_.uniq(ll))
    },
  /*  linkedTabs () {
      return ['clients', 'org', 'horsers']
    },*/
    airtableData () {
      var kkk = _.map(this.records, (k) => {
        var obj = k.fields;
        obj[this.airtableIdProp] = k.id;
        return obj
      });

      // transform according to rules:
    //  kkk = camelCasify(kkk)

      return kkk
    },

    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'New'
      }

      return ['AirJson', 'Feeds', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Configure a new data feed'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'client.new' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Data Feed'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Configure Feed'
      } else {
        return 'New Data Feed'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        name: null,
        company: null,
        city: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null,
        progress: 0
      }
    },
    selectLinkedTable() {

        alert('123333333');

    },

    updateBaseId () {
        this.records = [];
       //alert('12');
       this.getAirtable()
    },
    setLinkedTab(name, table) {
      //alert(name + table)
        this.linkedTabs[name] = table;
       //alert('12');
      // this.getAirtable()
    },
    createLinkedTab(name) {
      var table = prompt("Enter tab name exactly as-is on Airtable", "Table 1");
      this.setLinkedTab(name, table) ;
      return false;
    },
    isSelectedCols(name) {
       return _.includes(this.selectedCols, name)
      //  this.linkedTabs[name] = table;
       //alert('12');
      // this.getAirtable()
    },





    getAirtable () {
      this.isLoadingAt=true;
      axios({
        url: this.apiUrl + this.baseId + '/'+this.tableName,
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        },
        params: {
          filterByFormula: this.filter || '',
          sort: this.sort || ''
        }
      }).then((res) => {
        this.records = res.data.records;
        this.isLoadingAt=false;
      });
    },
    getData () {
      this.getAirtable()
      if (this.$route.params.id) {
        axios
          .get(`${this.$router.options.base}data-sources/clients.json`)
          .then((r) => {
            const item = find(
              r.data.data,
              (item) => item.id === parseInt(this.$route.params.id)
            )

            if (item) {
              this.isProfileExists = true
              this.form = item
              this.form.created_date = new Date(item.created_mm_dd_yyyy)
              this.createdReadable = dayjs(
                new Date(item.created_mm_dd_yyyy)
              ).format('MMM D, YYYY')
            } else {
              this.$router.push({ name: 'client.new' })
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    submit () {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false

        this.$buefy.snackbar.open({
          message: 'Demo only',
          queue: false
        })
      }, 500)
    }
  }
}
</script>
<style>
.chkboxlist .checkbox{

}
</style>
